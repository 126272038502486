@font-face {
    font-display: swap;
    font-family: 'MyriadPro-Bold';
    src: url('/fonts/MyriadPro-Bold.otf');
}
@font-face {
    font-display: swap;
    font-family: 'MyriadPro-SemiBold';
    src: url('/fonts/MyriadPro-SemiBold.otf');
}
@font-face {
    font-display: swap;
    font-family: 'MyriadPro-Regular';
    src: url('/fonts/MyriadPro-Regular.otf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: 0px solid #000;
    outline: none;
}

body {
    display: flex;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: 'MyriadPro-Bold', 'MyriadPro-Regular';
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url('/bg.jpg');
}

::-webkit-scrollbar {
    display: none;
}

#root {
    display: flex;
    width: 100%;
    height: 100%;
}

.form-control {
    text-align: justify;
    font-size: 1.2 rem;
    color: #fff;
    overflow-y: scroll;
    width: 300px;
    padding: 10px;
    font-weight: 600;
    background-color: #121212;
    border: 1px solid #ec1c24;
    border-radius: 2px;
}

@media (max-width: 835px) {
    .form-control {
        width: 100%;
    }
}

noscript,
.dummy-conteiner {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dummy-text-base {
    max-width: 750px;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
}
.dummy-text-desc {
    max-width: 750px;
    text-align: center;
    color: crimson;
    text-transform: uppercase;
    font-size: 44px;
}
#map {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
